const btnSetLocales = document.querySelectorAll('.btn-set-locale')

if (btnSetLocales.length > 0) {
  btnSetLocales.forEach(btn => {
    btn.addEventListener('click', function(){
      const lang = event.currentTarget.dataset.localeFor
      const url = `/locales/${lang}`
      fetch(url, {
        method: "PATCH"
      }).then(resp => {
        if(resp.ok) {
          window.location.reload()
        }
        })
      })
  })
}
