const btnDeleteCurriculum = document.getElementById('delete-curriculum')

if(btnDeleteCurriculum != null) {
  btnDeleteCurriculum.addEventListener('click', function(){
    const curriculumKey = event.currentTarget.dataset.curriculumKey

    if(confirm("Are you sure to delete curriculum ?")){
      fetch(`/curriculums/${curriculumKey}`, {
        method: 'DELETE'
      }).then(resp => {
        if(resp.ok) {
          window.location.replace('/')
        } else {
          Promise.reject(resp)
        }
      })
    }
  })
}
