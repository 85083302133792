import showGraph from './utils/show_graph.js'

const classifyForm = document.getElementById('classify-form')
const debugArea = document.getElementById('debug')

if(classifyForm != null){
  const topicsPanel = document.getElementById('topics-panel')
  const topicSelectBox = document.getElementById('curriculum-key');
  const classifyBtn = document.getElementById("classify-btn")
  let button = document.getElementById("download-graph");
  button.addEventListener("click", downloadGraphAsPng);

  setDefaultTopicPanelContent();
  addTopicSelectBoxEvent();
  addClassifyBtnEvent();

  async function setDefaultTopicPanelContent() {
    const getTopicUrl = `/classify_topics/${topicSelectBox.value}/topics`;
    const resp = await fetch(getTopicUrl,{ method: 'get' });
    const data = await resp.json();
    addTopicPanelContent(data);
    addTopicButtonEvent();
  }

  async function getTopics(curriculumKey) {
    const getTopicUrl = `/classify_topics/${curriculumKey}/topics`;
    const resp = await fetch(getTopicUrl,{ method: 'get' });
    const data = await resp.json();
    removeTopicPanelContent();
    addTopicPanelContent(data);
    addTopicButtonEvent();
  }

  function removeTopicPanelContent() {
    topicsPanel.innerHTML = '';
  }

  function addTopicPanelContent(data) {
    let avaliableTopics = [];
    data.forEach((topic, index) => {
      avaliableTopics.push(topic);
      topicsPanel.innerHTML += `
      <button class="btn-topic py-2 px-3 rounded-md border border-2 border-gray-400 bg-white cursor-pointer">${topic}</button>`
    });
    topicsPanel.dataset.availableTopics = JSON.stringify(avaliableTopics);
  }

  function addTopicButtonEvent() {
    const btnTopics = topicsPanel.querySelectorAll('.btn-topic');
    const selectedTopics = JSON.parse(topicsPanel.dataset.selectedTopics);
    btnTopics.forEach(btn => {
      btn.addEventListener('click', function(){
        let currentTopics = event.currentTarget.textContent;
        let currentTarget = event.currentTarget;
        if(selectedTopics.includes(currentTopics)) {
          selectedTopics.splice(selectedTopics.indexOf(currentTopics), 1);
          topicsPanel.dataset.selectedTopics = JSON.stringify(selectedTopics);
          replaceButtonClass(currentTarget, 'border-blue-400', 'border-gray-400')
          replaceButtonClass(currentTarget, 'bg-blue-100', 'bg-white')
        } else {
          selectedTopics.push(currentTopics);
          topicsPanel.dataset.selectedTopics = JSON.stringify(selectedTopics);
          replaceButtonClass(currentTarget, 'border-gray-400', 'border-blue-400')
          replaceButtonClass(currentTarget, 'bg-white', 'bg-blue-100')
        }
      })
    })
  }

  function replaceButtonClass(target, oldClass, newClass) {
    target.classList.replace(oldClass, newClass);
  }

  function addTopicSelectBoxEvent() {
    topicSelectBox.addEventListener('change', function(){
      const curriculumKey = event.currentTarget.value;
      getTopics(curriculumKey);
      removeSelectedTopics();
    });
  }

  function removeSelectedTopics() {
    topicsPanel.dataset.selectedTopics = JSON.stringify([]);
  }

  function addClassifyBtnEvent() {
    classifyBtn.addEventListener('click', function() {
      const curriculumKey = classifyForm.querySelector("#curriculum-key").value;
      const selectedTopics = JSON.parse(topicsPanel.dataset.selectedTopics);
      let url = `/classify_topics/${curriculumKey}/classify?`;
      selectedTopics.forEach(topic => {
        url += `&topic_list[]=${topic}`
      })
      getClassifyResult(url)
    })
  }
  
  async function getClassifyResult(url) {
    const graphPenel = document.getElementById('classify-result');
    const resp = await fetch(url,{ method: 'get' });
    const data = await resp.json();
    const selectTopic = document.getElementById("topic");

    selectTopic.innerHTML = '';
    graphPenel.dataset.graph = JSON.stringify(data);
    data['nodes'].forEach((topic, index) => {
      if (topic['selected_topic']) {
        let option = document.createElement( 'option' );
        option.value = topic['id'];
        option.text = topic['id'];
        selectTopic.appendChild( option );
      }
    })

    showGraph(graphPenel)
    button.classList.remove("hidden");
  }

  function downloadGraphAsPng() {
    const graphPenel = document.getElementById('classify-result');
    let canvas = graphPenel.getElementsByTagName("canvas")[0]
    if (canvas != null) {
      let graphUrl = canvas.toDataURL("image/png", 1.0);
      let link = document.createElement("a");
      link.download = "graph.png";
      link.href = graphUrl;
      link.click();
      link.remove();
    }
  }
}
