const editCurriculum = document.getElementById("edit-curriculum");

if (editCurriculum == null) { return; }

const modal = editCurriculum.querySelector("dialog");
const openBtn = document.getElementById("open-course-sequence");
const closeBtn = document.getElementById("close-course-sequence");

openBtn.addEventListener("click", (event) => {
  event.preventDefault();
  modal.showModal();
});

closeBtn.addEventListener("click", (event) => {
  event.preventDefault();
  modal.close();
});

modal.addEventListener("click", (event) => {
  const modalDimensions = modal.getBoundingClientRect();

  if (
    event.clientX < modalDimensions.left ||
    event.clientX > modalDimensions.right ||
    event.clientY < modalDimensions.top ||
    event.clientY > modalDimensions.bottom
  ) {
    modal.close();
  }
});
