import showGraph from "./utils/show_graph.js";

const courseGraph = document.getElementById("course-graph");
if (courseGraph != null) {
  showGraph(courseGraph);
  let button = document.getElementById("download-graph");
  let csvContent = createCSVContent();

  button.addEventListener("click", downloadGraphAsPng);
  setDownloadCSVButton(csvContent);
}

function createCSVContent() {
  let courseTitle = document.getElementById("course-title").innerText
  let courseCode = document.getElementById("course-code").innerText;
  let objectiveExpression = [...document.querySelector(".objective-expression > ul").getElementsByTagName("li")].map((item) => {
    return item.innerText
  });

  let topicExpression = [...document.querySelector(".topic-expression > ul").getElementsByTagName("li")].map((item) => {
    return item.innerText
  });

  let csvContent = "data:text/csv;charset=utf-8,"
  csvContent += courseCode + "," + courseTitle + "\n";
  csvContent += "," + topicExpression.join(",") + "\n";
  csvContent += "," + objectiveExpression.join(",\n,")

  return csvContent
}

function setDownloadCSVButton(csvContent) {
  let csvUrl = encodeURI(csvContent);
  let courseCode = document.getElementById("course-code").innerText;
  let downloadCSV = document.getElementById("download-csv");
  downloadCSV.setAttribute("href", csvUrl);
  downloadCSV.setAttribute("download", `${courseCode}.csv`);
}

function downloadGraphAsPng() {
  let graphUrl = courseGraph.getElementsByTagName("canvas")[0].toDataURL("image/png", 1.0);
  let link = document.createElement("a");
  link.download = "graph.png";
  link.href = graphUrl;
  link.click();
  link.remove();
}