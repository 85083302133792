import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

const clusteringForm = document.getElementById("clustering-form")
if (clusteringForm != null) {
  const clusteringResult = document.getElementById("clustering-result")
  const clusteringBtn = document.getElementById("clustering-btn")
  const curriculumSelectBox = document.getElementById('curriculum-key')
  const recommendGroupCheckbox = document.getElementById('recommend-group')
  const clusteringGroupInput = document.getElementById('clustering-group')
  const recommendedMessage = document.getElementById('recommended-message')
  const warningMessage = document.getElementById('warning-message')
  const downloadCSVButton = document.getElementById("download-csv");

  let courseCodeList = []
  
  setDefaultCoursePanelContent()
  addCurriculumSelectBoxEvent()
  addClusteringBtnEvent()
  addClusteringGroupInputEvent()
  addRecommendGroupCheckboxEvent()

  async function setDefaultCoursePanelContent() {
    const getCourseUrl = `/curriculums/${curriculumSelectBox.value}/courses`
    const resp = await fetch(getCourseUrl,{ method: 'get' })
    const data = await resp.json()
    addCoursePanelContent(data)
    addCourseCheckboxEvent()
  }

  async function getCourses(curriculumKey) {
    const getCourseUrl = `/curriculums/${curriculumKey}/courses`
    const resp = await fetch(getCourseUrl,{ method: 'get' })
    const data = await resp.json()
    addCoursePanelContent(data)
    addCourseCheckboxEvent()
  }

  function addCoursePanelContent(data) {
    const coursesPanel = document.getElementById('courses-panel')
    coursesPanel.innerHTML = Object.keys(data).map((course_code, index) => {
      return `
        <div data-course-name=${course_code} class="px-6 py-1 grid grid-cols-[50px_180px_1fr_50px] gap-x-4 first:pt-4 last:pb-4">
          <div>${ index + 1 }</div>
          <div>${ course_code }</div>
          <div class="overflow-hidden whitespace-nowrap truncate">${ data[course_code] }</div>
          <input type="checkbox" name="course-key" id="${ course_code }" value="${ course_code }">
        </div>
      `
    }).join('')
  }

  function addCourseCheckboxEvent() {
    const coursesCheckbox = document.querySelectorAll('input[name="course-key"]')
    coursesCheckbox.forEach(checkbox => {
      checkbox.addEventListener('change', function(){
        const courseKey = event.currentTarget.value
        const checked = event.currentTarget.checked

        if(checked) {
          courseCodeList.push(courseKey)
        } else {
          const index = courseCodeList.indexOf(courseKey)
          if(index > -1) {
            courseCodeList.splice(index, 1)
          }
        }
      })
    })
  }

  function addCurriculumSelectBoxEvent() {
    curriculumSelectBox.addEventListener('change', function() {
      const curriculumKey = event.currentTarget.value
      getCourses(curriculumKey)
      courseCodeList = [];
      clusteringResult.innerHTML = ""
      downloadCSVButton.classList.add("hidden")
    })
  }

  function addClusteringBtnEvent() {
    clusteringBtn.addEventListener('click', function() {
      let curriculumKey = clusteringForm.querySelector("#curriculum-key").value
      let groupNumber = clusteringGroupInput.value

      if (courseCodeList == "") {
        showWarningMessage(clusteringForm.dataset.courseRequired)
        return
      }

      if (groupNumber == "" && recommendGroupCheckbox.checked == false) {
        clusteringGroupInput.focus()
        return
      }

      let url = `/clusterings/${curriculumKey}?group_number=${groupNumber}`

      courseCodeList.map(courseCode => {
        url += `&course_code[]=${courseCode}`
      })

      url += `&recommended_group_number=${recommendGroupCheckbox.checked}`
      showBackDropAndSpinner()
      getClusteringResult(url)
    })
  }

  function addClusteringGroupInputEvent() {
    clusteringGroupInput.addEventListener('change', function() {
      let groupNumber = event.currentTarget.value
      if (groupNumber < 2) {
        event.currentTarget.value = 2
      }
    })

    clusteringGroupInput.addEventListener('focus', function() {
      clusteringGroupInput.classList.remove("border-red-400")
      warningMessage.classList.add("hidden")
    })
  }

  function addRecommendGroupCheckboxEvent() {
    recommendGroupCheckbox.addEventListener('change', function() {
      const checked = event.currentTarget.checked
      if(checked) {
        clusteringGroupInput.disabled = true
        clusteringGroupInput.value = ""
        clusteringGroupInput.placeholder = clusteringGroupInput.dataset.recommendedPlaceholder
        clusteringGroupInput.classList.remove("border-red-400")
        warningMessage.classList.add("hidden")
      }
      else {
        clusteringGroupInput.disabled = false
        clusteringGroupInput.placeholder = clusteringGroupInput.dataset.requiredPlaceholder
      }
    })
  }

  function addTooltipForClusteringModule() {
    const topicList = clusteringResult.querySelectorAll("li")

    topicList.forEach(topic => {
      const content = topic.dataset.fromCourse
      const text = topic.children[0]

      tippy(topic, {
        content: content,
        placement: "right",
        animation: "fade",
        getReferenceClientRect: () => ({
          width: text.getBoundingClientRect().width,
          height: text.getBoundingClientRect().height,
          left: text.getBoundingClientRect().left,
          right: text.getBoundingClientRect().right,
          top: text.getBoundingClientRect().top,
          bottom: text.getBoundingClientRect().bottom
        })
      })
    })
  }

  function getCourseCodeList(data) {
    let courseCode = []
    let courseName = []
    data.topics.forEach(topic => {
      if (courseCode.includes(topic.courseCode[0]) == false) {
        courseCode = courseCode.concat(topic.courseCode)
      }

      if (courseName.includes(topic.fromCourse[0]) == false) {
        courseName = courseName.concat(topic.fromCourse)
      }
    })
    return [ courseCode, courseName ]
  }

  function createCourseContent(course, courseName, row_start, row_end, gridContainer) {
    let courseElement = document.createElement("div")
    courseElement.classList.add("border-b", "border-gray-500", "col-start-2")
    courseElement.style.gridRowStart = row_start
    courseElement.style.gridRowEnd = row_end
    courseElement.innerText = course

    let courseNameElement = document.createElement("div")
    courseNameElement.classList.add("border-b", "border-gray-500", "col-start-3")
    courseNameElement.style.gridRowStart = row_start
    courseNameElement.style.gridRowEnd = row_end
    courseNameElement.innerText = courseName

    return [courseElement, courseNameElement]
  }

  function createTopicContent(item, gridContainer) {
    let [ courseCodes, courseName ] = getCourseCodeList(item)
    let row_start = 1

    courseCodes.forEach((course, index) => {
      let topics_in_course = item.topics.filter(topic => {
        return topic.courseCode.includes(course)
      })

      let row_end = row_start + topics_in_course.length
      topics_in_course.forEach((topic, index) => {
        let topicElement = document.createElement("div")

        topicElement.classList.add("col-start-1")
        if (topics_in_course.length == index + 1) {
          topicElement.classList.add("border-b", "border-gray-500")
        }

        topicElement.innerText = topic.name
        gridContainer.appendChild(topicElement)
      })
      
      let [courseElement, courseNameElement] = createCourseContent(course, courseName[index], row_start, row_end, gridContainer)

      gridContainer.appendChild(courseElement)
      gridContainer.appendChild(courseNameElement)
      row_start = row_end
    })
  }

  async function getClusteringResult(url) {
    const resp = await fetch(url,{ method: 'get' });
    hideBackDropAndSpinner()
    if (resp.status != 200) {
      const data = await resp.json();
      showWarningMessage(data.error_message)
      return 
    }

    const data = await resp.json();
    clusteringResult.innerHTML = ""
    recommendGroupCheckbox.checked ? recommendedMessage.classList.remove("hidden") : recommendedMessage.classList.add("hidden")
    data.forEach(item => {
      let moduleContainer = document.createElement("div")
      moduleContainer.classList.add(`module-${item.cluster}`, "mb-6")
      moduleContainer.innerHTML = `
      <div class="mb-2"><span class="font-semibold">โมดูลที่ ${item.cluster}</span> : หัวข้อการเรียนรู้ ประกอบด้วย</div>
      `

      let gridContainer = document.createElement("div")
      gridContainer.classList.add("grid", "grid-cols-[50%_20%_30%]")
      createTopicContent(item, gridContainer)
      
      moduleContainer.appendChild(gridContainer)
      clusteringResult.appendChild(moduleContainer)
    })

    clusteringResult.scrollIntoView({ behavior: "smooth", block: "nearest" })
    addTooltipForClusteringModule()
    createClusteringCSV(data)
  }

  function createClusteringCSV(data) {
    let header = "โมดูล, หัวข้อการเรียนรู้, รหัสวิชา, ชื่อวิชา"
    let csvContent = "data:text/csv;charset=utf-8,"
    csvContent += header + "\n"
    data.forEach(item => {
      let [ courseCodes, courseName ] = getCourseCodeList(item)
      courseCodes.forEach((course, index) => {
        let topics_in_course = item.topics.filter(topic => {
          return topic.courseCode.includes(course)
        });
        topics_in_course.forEach(topic => {
          csvContent += `โมดูลที่ ${item.cluster}, ${topic.name}, ${courseCodes[index]}, ${courseName[index]}\n`
        })
      })
    })
    let csvUrl = encodeURI(csvContent);
    downloadCSVButton.classList.remove("hidden")
    downloadCSVButton.setAttribute("href", csvUrl);
    downloadCSVButton.setAttribute("download", 'clustering_result.csv');
  }

  function showBackDropAndSpinner() {
    const spinner = document.getElementsByClassName("spinner-backdrop")[0]
    console.log(spinner);
    spinner.classList.add('show')
  }

  function hideBackDropAndSpinner() {
    const spinner = document.getElementsByClassName("spinner-backdrop")[0]
    spinner.classList.remove('show')
  }

  function showWarningMessage(message) {
    warningMessage.classList.remove("hidden")
    warningMessage.innerText = message
    clusteringGroupInput.classList.add("border-red-400")
    recommendedMessage.classList.add("hidden")
  }
}