import Uppy from '@uppy/core'
import Form from '@uppy/form';
import Dashboard from '@uppy/dashboard'
import XHR from '@uppy/xhr-upload';

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'

const uploadForOwl = document.getElementById('upload-for-owl-form')
const upload2 = document.getElementById('upload-2')
if(uploadForOwl != null && upload2 != null) {
  const endpoint = upload2.dataset.endpoint
  const uppy = new Uppy({
    restrictions: {
      allowedFileTypes: ['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      maxNumberOfFiles: 1,
    },
    onBeforeFileAdded: (file, files) => {
      clearUploadErrorMessage()
    }
  })
   .use(Dashboard, { 
     inline: true,
     target: '#upload-2',
     width: 810,
     height: 250
   })
  .use(Form, {
    target: '#upload-for-owl-form'
  })
  .use(XHR, { 
    endpoint: endpoint,
    getResponseData(respText, resp) {
      if(resp.status == 200) {
        const data = JSON.parse(respText)
        showAddedCourseToPanel(data)
        showOwlDownloadLink(data)
        return { url: resp.responseURL }
      } 
    },
    getResponseError: (respText, resp) => {
      const error_message = JSON.parse(respText).error_message
      showUploadErrorMessage(error_message)
    }
  })
}

function clearUploadErrorMessage() {
  const errMessage = document.getElementById('upload-error')
  errMessage.textContent = '' 
  errMessage.classList.remove("text-red-500", "text-sm", "mt-4", "p-2")
}

function showUploadErrorMessage(error_message) {
  const errMessage = document.getElementById('upload-error')
  errMessage.classList.add("text-red-500", "text-sm", "mt-4", "p-2")
  errMessage.textContent = error_message
}

function showOwlDownloadLink(data) {
  const owlContent = document.getElementById('owl-content')
  if(owlContent == null) { return }
  
  owlContent.innerHTML = `<div>Upload course ${data.course_code} successfully. <a href="/owl/${data.curriculum_key}.owl" class="underline text-blue-500">Download OWL file</a></div>`
}

function hasExistingCourseInPanel(coursesPanel, courseName) {
  return coursesPanel.querySelectorAll(`[data-course-name="${courseName}"]`).length > 0
}

function showAddedCourseToPanel(data) {
  const coursesPanel = document.getElementById('courses-panel')
  if(coursesPanel == null) { return }
  if(coursesPanel.lastElementChild == null) {
    const item = `
        <div data-course-name="${ data.course_name }" class="px-6 py-1 grid grid-cols-[50px_180px_1fr_50px] gap-x-4 first:pt-4 last:pb-4">
          <div style="color:#57CC99;font-weight:800">New!</div>
          <div>${ data.course_name }</div>
      <div class="overflow-hidden whitespace-nowrap truncate">${data.course_title}</div>
      <button title="Delete course ${ data.course_name }" data-curriculum-key="${ data.curriculum_key }" data-delete-course="${ data.course_name }" class="btn-delete-course text-sm underline flex justify-center" style="opacity:0.3;" disabled>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="20px" height="20px" viewBox="0 0 24 24"><path fill="#5e5e5e" fill-rule="evenodd" d="M7.1 5H3a1 1 0 0 0 0 2h1.06l.825 13.187A3 3 0 0 0 7.879 23h8.242a3 3 0 0 0 2.994-2.813L19.94 7H21a1 1 0 1 0 0-2h-4.1a5.002 5.002 0 0 0-9.8 0Zm2.07 0h5.66a3.001 3.001 0 0 0-5.66 0Zm8.766 2H6.064l.817 13.062a1 1 0 0 0 .998.938h8.242a1 1 0 0 0 .998-.938L17.936 7Zm-3.657 3.01a1 1 0 0 1 .928 1.067l-.419 5.985a1 1 0 1 1-1.995-.14l.419-5.984a1 1 0 0 1 1.067-.928Zm-4.558 0a1 1 0 0 1 1.067.928l.419 5.985a1 1 0 0 1-1.995.14l-.419-5.986a1 1 0 0 1 .928-1.067Z" clip-rule="evenodd"/></svg>
          </button>
        </div>
    ` 
    coursesPanel.insertAdjacentHTML('beforeend', item)
    return
  }

  if(!hasExistingCourseInPanel(coursesPanel, data.course_name)) {
    let newItem = coursesPanel.lastElementChild.cloneNode(true)
    newItem.querySelector("div:nth-child(1)").textContent = "New!"
    newItem.querySelector("div:nth-child(1)").style.cssText = "color:#57CC99;font-weight:800"
    newItem.querySelector("div:nth-child(2)").textContent = data.course_name
    newItem.querySelector("div:nth-child(3)").textContent = data.course_title
    newItem.dataset.courseName = data.course_name

    let btnDelete = newItem.querySelector("button")
    btnDelete.dataset.deleteCourse = data.course_name
    btnDelete.dataset.curriculumKey = data.curriculum_key
    btnDelete.disabled = true
    btnDelete.style.cssText = "opacity:0.3;"

    coursesPanel.appendChild(newItem)
    coursesPanel.scrollTo({top: 500, behaviour: "smooth"})
  }
}
