export default function wrapTopic(node) {
  let longestText = "",
    textList = [],
    textHeight = 0;
  const nodeText = node.id;
  const separator = "และ";

  if (nodeText.includes(separator)) {
    textList = nodeText.split(separator);
    textList[0] = textList[0] + separator;

    longestText = textList.reduce((longestString, currentString) => {
      return currentString.length > longestString.length
        ? currentString
        : longestString;
    });

    const lines = textList.length;
    const height = 8;
    const gap = 8;
    textHeight = lines * height + gap;
  }

  return {
    textFromLongTopic: longestText,
    textList: textList,
    heightForTwoLines: textHeight,
  };
}
