const editCurriculum = document.getElementById('edit-curriculum')
if(editCurriculum == null) { return }

const btnDeleteCourses = document.querySelectorAll("button[data-delete-course]")
btnDeleteCourses.forEach(btn => {
  const coursesPanel = document.getElementById('courses-panel')
  btn.addEventListener('click', function(){
    event.preventDefault()
    const courseName = event.currentTarget.dataset.deleteCourse
    const curriculumKey = event.currentTarget.dataset.curriculumKey
    const deleteCourseUrl = `/curriculums/${curriculumKey}/courses/${courseName}`

    if(!window.confirm(`Are you sure to delete course ${courseName} ?`)) {
      return
    }

    fetch(deleteCourseUrl, {
      method: "DELETE"
    }).then(resp => resp.ok ? resp.json() : Promise.reject(resp))
      .then(data => {
        let courseLineItem = coursesPanel.querySelector(`[data-course-name="${courseName}"]`)
        courseLineItem.remove()
        // TODO:
        // Show message that the course is successfully delete!
      })
      .catch(resp => console.error(resp))
  })
})
