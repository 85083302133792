import wrapLongTopic from "./node_decoration";

import * as d3 from "d3-force";
import ForceGraph from "force-graph";


let canvasWidth = 0

function showGraph(graphPenel) {
  canvasWidth = graphPenel.clientWidth
  let graphObject = JSON.parse(graphPenel.dataset.graph);
  let graphData = sortNodes(graphObject)
  let topicData = getTopicGraphData(graphData)
  const displayTypeBtns = document.querySelectorAll('input[name="graph-display-type"]');
  
  setLinkCurve(graphData)
  createGraph(graphPenel, graphData, topicData)
  
  if (displayTypeBtns != null) {
    addDisplayTypeBtnsEvent(displayTypeBtns, graphPenel)
  }
}

function sortNodes(data) {
  const nodes = data.nodes
  let courseNodes = [],
    objectiveNodes = [],
    topicNodes = []

  nodes.forEach(n => {
    switch(n.type) {
      case 'courses': courseNodes.push(n); break;
      case 'learning_objectives': objectiveNodes.push(n); break;
      case 'topics': topicNodes.push(n); break;
    }
  })
  let sortedObjectiveNodes = objectiveNodes.sort((a,b) => {
    let indexA = Number(a.id.match(/\d*$/)[0])
    let indexB = Number(b.id.match(/\d*$/)[0])
    return indexB - indexA
  })
  const newNodes = [...courseNodes, ...sortedObjectiveNodes, ...topicNodes]
  return { links: data.links, nodes: newNodes}
}

function addDisplayTypeBtnsEvent(displayTypeBtns, graphPenel) {
  const selectObjective = document.getElementById("select-objective");
  const selectTopic = document.getElementById("select-topic");
  
  displayTypeBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      let graphData = JSON.parse(graphPenel.dataset.graph);
      let topicData = getTopicGraphData(graphData)
      graphPenel.textContent = ""
      selectObjective?.classList.add("hidden")
      selectTopic?.classList.add("hidden")
      
      switch (btn.value) {
        case "learning-objectives":
          selectObjective.classList.remove("hidden");
          getObjectiveGraphData(graphPenel)
          break
          
        case "topics":
          const topicGraphData = getTopicGraphData(graphData)
          setLinkCurve(topicGraphData)
          createGraph(graphPenel, topicGraphData, topicData)
          break
        
        case "topics-at-classify":
          selectTopic?.classList.remove("hidden")
          getTopicGraphDataAtClassifyPage(graphPenel)
          break
          
        default:
          setLinkCurve(graphData)
          createGraph(graphPenel, graphData, topicData)
          break
      }
    })
  })
}
        
function setLinkCurve(graphData) {
  const curvatureMinMax = 0.125;
  let sameSourceAndTargetNode = {};

  graphData.links.forEach((link) => {
    link.nodePairId = link.source + "_" + link.target;

    if (!sameSourceAndTargetNode[link.nodePairId]) {
      sameSourceAndTargetNode[link.nodePairId] = [];
    }
    sameSourceAndTargetNode[link.nodePairId].push(link);
  });

  // Compute the curvature for links sharing the same two nodes to avoid overlaps
  Object.keys(sameSourceAndTargetNode)
    .filter((nodePairId) => sameSourceAndTargetNode[nodePairId].length > 1)
    .forEach((nodePairId) => {
      let links = sameSourceAndTargetNode[nodePairId];
      let lastIndex = links.length - 1;
      let lastLink = links[lastIndex];
      lastLink.curvature = curvatureMinMax;
      let delta = (2 * curvatureMinMax) / lastIndex;
      for (let i = 0; i < lastIndex; i++) {
        links[i].curvature = -curvatureMinMax + i * delta;
        if (lastLink.source !== links[i].source) {
          links[i].curvature *= -1; // flip it around, otherwise they overlap
        }
      }
    });
}

function getNodeColor(node) {
  if (node.selected_topic) {
    return "#7209B7";
  }

  switch (node.type) {
    case "learning_objectives": return "#38a3a5";
    case "courses":             return "#22577a";
    case "topics":              return "#57cc99";
    default: return "#909090";
  }
}

function getObjectiveGraphData(graphPanel) {
  const selectObjectiveBtn = document.querySelector("#select-objective button");
  
  selectObjectiveBtn.addEventListener("click", () => {
    let graphData = JSON.parse(graphPanel.dataset.graph);
    let topicData = getTopicGraphData(graphData)
    let learningObjectiveGraphData = getGraphDataByObjectiveSelected(graphData)
    setLinkCurve(learningObjectiveGraphData);
    createGraph(graphPanel, learningObjectiveGraphData, topicData);
  });
}

function getGraphDataByObjectiveSelected(graphData) {
  let newNodes = [];
  let newLinks = [];
  const objectiveSelectList = document.querySelector("select#learning-objective");
  const objectiveSelected = objectiveSelectList.value;

  graphData.links.forEach((link) => {
    if (link.source == objectiveSelected) {
      newLinks.push(link);

      const learningObjective = link.source
      const topic = link.target              
      const nodeId = newNodes.map((node) => node.id);

      if (!nodeId.includes(learningObjective)) {
        newNodes.push({id: learningObjective, type: "learning_objectives"});
      }

      if (!nodeId.includes(topic)) {
        newNodes.push({id: topic, type: "topics"});
      }
    }
  })
  return { nodes: newNodes, links: newLinks };
}

function getTopicGraphDataAtClassifyPage(graphPanel) {
  const selectTopicBtn = document.querySelector("#select-topic button");
  
  selectTopicBtn.addEventListener("click", () => {
    let graphData = JSON.parse(graphPanel.dataset.graph);
    let topicData = getTopicGraphData(graphData)
    const topicGraphData = getGraphDataByTopicSelected(graphData)
    setLinkCurve(topicGraphData);
    createGraph(graphPanel, topicGraphData, topicData);
  });
}

function getGraphDataByTopicSelected(graphData) {
  let newNodes = [];
  let newLinks = [];
  const topicSelectList = document.querySelector("select#topic");
  const topicSelected = topicSelectList.value;
  graphData.links.forEach((link) => {
    if (link.source == topicSelected) {
      newLinks.push(link);

      const nodeTopic = link.source
      const linkTopic = link.target
      newNodes.push(
        {id: nodeTopic, type: "start_topics", selected_topic: true}, 
        {id: linkTopic, type: "topics"}
      )

      const allContinueTopics = recursiveGetTopic(linkTopic, graphData.links);

      newNodes.push(...allContinueTopics.nodes);
      newLinks.push(...allContinueTopics.links);

      newNodes = newNodes.filter((value, index) => {
        return index == newNodes.findIndex((node) => node.id == value.id);
      })
      
      newLinks = newLinks.filter((value, index) => {
        return index == newLinks.findIndex((link) => link.source == value.source && link.target == value.target);
      })
    }
  })

  if(newNodes.length == 0) {
    newNodes.push({id: topicSelected, type: "start_topics", selected_topic: true})
  }
  return { nodes: newNodes, links: newLinks };
}

function recursiveGetTopic(topic, topicList) {
  let newNodes = [];
  let newLinks = [];
  topicList.forEach((link) => {
    if (link.source == topic) {
      newLinks.push(link);

      const nodeTopic = link.source
      const linkTopic = link.target
      newNodes.push(
        {id: nodeTopic, type: "topics"}, 
        {id: linkTopic, type: "topics"}
      )
      let nodesAndLinks = recursiveGetTopic(linkTopic, topicList)
      newNodes.push(...nodesAndLinks.nodes)
      newLinks.push(...nodesAndLinks.links)
    }
  })
  return { nodes: newNodes, links: newLinks };
}

function getTopicGraphData(graphData) {
  const newNodes = graphData.nodes.filter((node) => {
    return node.type === "topics"
  })

  const newLinks = graphData.links.filter((link) => {
    return link.name === "continueFrom"
  })

  return JSON.parse(JSON.stringify({ nodes: newNodes, links: newLinks }))
}

function setPositionOfLearningObjectiveNodes(graphData) {
  const distance = 30
  const learningObjList = graphData.nodes.filter((node) => node.type === "learning_objectives")
  const halfIndex = Math.ceil(learningObjList.length / 2)
  const evenNodes = learningObjList.length % 2 === 0

  graphData.nodes.forEach((node, index) => {
    let position

    if (node.type === "learning_objectives") {
      position = (index - halfIndex) * distance

      if (evenNodes) {
        position = position - distance / 2
      }

      node.fy = position
    }
  })
}

function setPositionNodeOfTopicGraph(topicData) {
  const topicNodes = topicData.nodes
  const halfIndex = Math.floor(topicNodes.length / 2)
  const evenNodes = topicNodes.length % 2 === 0
  const paddingLeftRight = 2 * 300
  const distance = (canvasWidth - paddingLeftRight) / topicNodes.length

  topicNodes.forEach((node, index) => {
    let position = (index - halfIndex) * distance

    if (evenNodes) {
      position = position + distance / 2
    }
    node.fx = position
  })
}

function createGraph(graphPenel, graphData, topicData) {
  let initialPosition = true
  // const canvasWidth = graphPenel.clientWidth
  const graph = ForceGraph()(graphPenel)

  graph.graphData(graphData)
    .width(canvasWidth)
    .height("600")
    .nodeCanvasObject((node, ctx) => {
      const { textFromLongTopic, textList, heightForTwoLines } =
        wrapLongTopic(node);
      const height = 14;
      const nodeText = textFromLongTopic || node.id;
      const textWidth = ctx.measureText(nodeText).width + 8;
      const textHeight = heightForTwoLines || height;
      const xAxis = node.x - textWidth / 2;
      const yAxis = node.y - textHeight / 2;

      // Draw node label with rectangle background
      const nodeColor = getNodeColor(node)
      const nodeLabelProp = [xAxis, yAxis, textWidth, textHeight]
      ctx.font = `8px Sans-Serif`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.lineJoin = "bevel";
      ctx.fillStyle = "#f4f9f7";
      ctx.strokeStyle = nodeColor
      ctx.strokeRect(...nodeLabelProp);
      ctx.fillRect(...nodeLabelProp);

      ctx.fillStyle = nodeColor;
      if (textList.length > 0) {
        textList.forEach((text, index) => {
          const yAxis = node.y - 5 + index * 10;
          ctx.fillText(text, node.x, yAxis);
        });
      } else {
        ctx.fillText(nodeText, node.x, node.y);
      }

      // To use in nodePointerAreaPaint for detect pointer interaction
      node.dimensions = nodeLabelProp;
    })
    .nodeCanvasObjectMode(() => 'replace')
    .nodePointerAreaPaint((node, color, ctx) => {
      ctx.fillStyle = color
      // 1) dimensions is set of width, height, x and y coordinates of each node
      // Expected to be a number e.g. [111.3968, -17.9843, 17.2062, 14] cannot be 0
      // 2) ctx.fillRect(...dimensions) this call fillRect() method to draw a rectangle
      // 3) Possible result of "dimensions && ctx.fillRect(...dimensions)" operand are...
      //  3.1) undefined when one of element in dimensions array is falsy (0, undefined, "", etc)  
      //  3.2) ctx.fillRect(...dimensions) when all elements in dimensions array be a number following to 1)
      const dimensions = node.dimensions
      dimensions && ctx.fillRect(...dimensions)
    })
    .linkLabel("name")
    .linkCurvature("curvature")
    .linkDirectionalArrowLength(5)
    .linkDirectionalArrowRelPos(0.585)
    .linkCanvasObjectMode(() => "after")
    .linkCanvasObject((link, ctx) => {
      ctx.fillStyle = "#7a7a7a";
      ctx.font = `6px Sans-Serif`;
      ctx.save();

      const curvature = link.curvature;
      if (curvature === undefined) {
        // Have one link
        const xAxis = link.source.x + (link.target.x - link.source.x) / 2;
        const yAxis = link.source.y + (link.target.y - link.source.y) / 2;
        const distanceMoveInHorizontal = link.target.y - link.source.y;
        const distanceMoveInVertical = link.target.x - link.source.x;
        let angle = Math.atan2(
          distanceMoveInHorizontal,
          distanceMoveInVertical
        );

        // maintain label vertical orientation for legibility
        if (angle > Math.PI / 2) angle = -(Math.PI - angle);
        if (angle < -Math.PI / 2) angle = -(-Math.PI - angle);

        ctx.translate(xAxis, yAxis);
        ctx.rotate(angle);
        ctx.fillText(link.title, 0, 6);
        ctx.restore();
      } else {
        const start = link.source;
        const end = link.target;
        const lineLength = Math.sqrt(
          Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
        );
        const angle = Math.atan2(end.y - start.y, end.x - start.x); // line angle
        const distance = lineLength * curvature; // control point distance

        const controlPointX =
          (start.x + end.x) / 2 + distance * Math.cos(angle - Math.PI / 2);
        const controlPointY =
          (start.y + end.y) / 2 + distance * Math.sin(angle - Math.PI / 2);

        const midPoint1X = start.x + (controlPointX - start.x) / 2;
        const midPoint1Y = start.y + (controlPointY - start.y) / 2;
        const midPoint2X = controlPointX + (end.x - controlPointX) / 2;
        const midPoint2Y = controlPointY + (end.y - controlPointY) / 2;
        const xAxis = midPoint1X + (midPoint2X - midPoint1X) / 2;
        const yAxis = midPoint1Y + (midPoint2Y - midPoint1Y) / 2;

        ctx.fillText(link.title, xAxis, yAxis);
        ctx.restore();
      }
    })
    .dagMode("lr")
    .dagLevelDistance(85)
    .cooldownTicks(50)
    .onEngineTick(() => {
      if (initialPosition) {
        setPositionOfLearningObjectiveNodes(graphData)
        setPositionNodeOfTopicGraph(topicData)
      }
    })
    .onNodeDrag((node) => {
      initialPosition = false
      node.fy = node.y
    })
    .onNodeDragEnd((node) => {
      node.fx = node.x
      node.fy = node.y
    })
    .onDagError((error) => console.log(error))
    .onRenderFramePost(_ => {
      // Move course node away from learning objective node to the left
      const nodes = graph.graphData().nodes
      const course = nodes.find(n => n.type == 'courses')
      if(course) {
        const firstObjective = nodes.find(n => n.id == `${course.id}_LO1`)
        course.x = firstObjective.x - 130
      }
    })

  graph
    .d3Force("link", d3.forceLink().distance(120))
    .d3Force('collide', d3.forceCollide(45).iterations(3))
  graph.onEngineStop(() => {
    if(graphData.nodes.length > 5) {
      graph.zoomToFit(0, 75)
    }
  });
}


export default showGraph;